.contact-container > .contact-header {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

/* ------------FOrm-------- */

.contact-content > .contact-left {
  display: flex;
  flex-direction: column;
  gap: 1.3rem;
}
.contact-left > h3 {
  font-size: 1.3rem;
  font-weight: 600;
  color: #000;
}
.contact-left > p {
  font-size: 1rem;
  font-weight: 400;
  color: #555;
}
.contact-left > span {
  width: 2.5rem;
  height: 1px;
  background: #777;
  margin: 0.5rem 0;
}
.contact-left > .cleft-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-right: 2rem;
  /* gap: 4rem; */
}
.cleft-content .content-box {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}
.content-box > h3 {
  font-size: 1.2rem;
  font-weight: 500;
  color: #111;
}
.contact-icons > ul {
  display: flex;
  align-items: center;
  gap: 3rem;
}
.contact-icons > ul > a {
  text-decoration: none;
  cursor: pointer;
}
.contact-icons > ul > a > li > svg {
  font-size: 1.8rem;
}

/* contact-Right */
.contact-Right {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.contact-Right h3 {
  font-size: 1.3rem;
  font-weight: 600;
  color: #000;
}
.contact-Right > form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.1rem;
  padding: 0.5rem 0.5rem;
  border-radius: 0.3rem;
  border: 2px solid #ccc;
  align-items: flex-start;
}
form > .Budget {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.Budget span {
  font-size: 1.2rem;
  font-weight: 500;
  color: #000;
}
.lables {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Adjust the column layout as needed */
  grid-gap: 2rem;
  margin-left: 1rem;
}
.lables label {
  display: flex;
  gap: 0.8rem;
  align-items: center;
  cursor: pointer;
}
.lables label input[type="radio"] {
  transform: scale(1.5); /* Adjust the scale factor to change the size */
}
/* imput fields */
.inputBox {
  width: 100%;
  position: relative;
}
.inputBox > input,
.inputBox > textarea {
  width: 100%;
  height: 3rem;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  outline: none;
  border-radius: 0.3rem;
  padding: 10px;
  overflow: none;
  resize: none;
}
.inputBox > textarea {
  height: 6rem;
}

form > .submit-button {
  width: 100%;
  padding: 1rem 1rem;
}
.submit-button > button {
  padding: 0.4rem 0.8rem;
  font-size: 1rem;
  font-weight: 500;
  color: #fff;
  background: #000;
  cursor: pointer;
  border-radius: 2rem;
  float: right;
}
.inputBox > span {
  position: absolute;
  left: 0;
  padding: 12px;
  pointer-events: none;
  font-size: 1rem;
  color: #888;
  transition: 0.3s;
}
.inputBox > input:valid ~ span,
.inputBox > textarea:valid ~ span,
.inputBox > input:focus ~ span,
inputBox > textarea:focus ~ span {
  color: #000;
  transform: translateX(10px) translateY(-6px);
  font-size: 0.8rem;
  padding: 0 7px;
  background: #fff;
  border-left: 2px solid #000;
  border-right: 2px solid #000;
}
/* .inputBox > input:valid,
  .inputBox > input:focus {
    border: 2px solid rgb(0, 0, 0);
  } */

/* Responsiveness */

@media (max-width: 600px) {
  .contact-container {
    padding: 4rem 1rem;
  }
  .contact-content {
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  }
}
