@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap");

.heading {
  font-family: "Playfair Display", serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.highlight {
  color: #cea82a;
}

.message::-webkit-scrollbar {
  display: none;
}

blockquote,
.jodit-wysiwyg dl,
.jodit-wysiwyg dd,
.jodit-wysiwyg h1,
.jodit-wysiwyg h2,
.jodit-wysiwyg h3,
.jodit-wysiwyg h4,
.jodit-wysiwyg h5,
.jodit-wysiwyg h6,
.jodit-wysiwyg hr,
.jodit-wysiwyg figure,
.jodit-wysiwyg p,
.jodit-wysiwyg pre {
  margin: 0;
  color: #111;
}

*::-webkit-scrollbar {
  width: 0.4rem;
  border-radius: 1rem;
}

*::-webkit-scrollbar-thumb {
  background-color: #cea82a;
  border-radius: 1rem;
}

*::-webkit-scrollbar-track {
  width: 0.4rem;
}
